@media screen and (min-width: 1500px){
    #root-hero-search{ max-width: 675px; }
    #hero-select-input{ width: 185px }
}
@media screen and (min-width: 1200px) and (max-width: 1500px){
    #root-hero-search{ max-width: 580px; }
}
@media screen and (min-width: 1000px) and (max-width: 1200px){
    #root-hero-search{ max-width: 480px; }
    #hero-input-container{ max-width: 50%; }
}
@media screen and (min-width: 1000px) and (max-width: 1500px){
    #hero-select-input{ width: 160px }
}
@media screen and (min-width: 700px) and (max-width: 1000px){
    #root-hero-search{ max-width: 480px; }
    #hero-select-input{ width: 140px }
}